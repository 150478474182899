@charset "UTF-8";
@import url("https://use.typekit.net/mmw4asr.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css");

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "proxima-nova", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

@media print,
screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print,
screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.collapse>.column,
.row.collapse>.columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse>.column>.row,
.row.collapse>.columns>.row {
  margin-right: 0;
  margin-left: 0;
}

.column,
.columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}

@media print,
screen and (min-width: 40em) {
  .column,
  .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row,
.row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row,
.row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1>.column,
.small-up-1>.columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2>.column,
.small-up-2>.columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3>.column,
.small-up-3>.columns {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4>.column,
.small-up-4>.columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5>.column,
.small-up-5>.columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6>.column,
.small-up-6>.columns {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7>.column,
.small-up-7>.columns {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8>.column,
.small-up-8>.columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse>.column,
.small-collapse>.columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse>.column,
.small-uncollapse>.columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print,
screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1>.column,
  .medium-up-1>.columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2>.column,
  .medium-up-2>.columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3>.column,
  .medium-up-3>.columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4>.column,
  .medium-up-4>.columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5>.column,
  .medium-up-5>.columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6>.column,
  .medium-up-6>.columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7>.column,
  .medium-up-7>.columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8>.column,
  .medium-up-8>.columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print,
screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

.row.medium-unstack>.column,
.row.medium-unstack>.columns {
  flex: 0 0 100%;
}

@media print,
screen and (min-width: 40em) {
  .row.medium-unstack>.column,
  .row.medium-unstack>.columns {
    flex: 1 1 0px;
  }
}

@media print,
screen and (min-width: 40em) {
  .medium-collapse>.column,
  .medium-collapse>.columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse>.column,
  .medium-uncollapse>.columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print,
screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1>.column,
  .large-up-1>.columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2>.column,
  .large-up-2>.columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3>.column,
  .large-up-3>.columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4>.column,
  .large-up-4>.columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5>.column,
  .large-up-5>.columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6>.column,
  .large-up-6>.columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7>.column,
  .large-up-7>.columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8>.column,
  .large-up-8>.columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print,
screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

.row.large-unstack>.column,
.row.large-unstack>.columns {
  flex: 0 0 100%;
}

@media print,
screen and (min-width: 64em) {
  .row.large-unstack>.column,
  .row.large-unstack>.columns {
    flex: 1 1 0px;
  }
}

@media print,
screen and (min-width: 64em) {
  .large-collapse>.column,
  .large-collapse>.columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse>.column,
  .large-uncollapse>.columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}

.column-block> :last-child {
  margin-bottom: 0;
}

@media print,
screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block> :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.2rem;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
.single .article-content blockquote p,
.widget ul li,
h6,
.h6,
.rp4wp-related-posts h3 {
  font-family: "proxima-nova", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h4 small,
.h4 small,
h5 small,
.h5 small,
.single .article-content blockquote p small,
.widget ul li small,
h6 small,
.h6 small,
.rp4wp-related-posts h3 small {
  line-height: 0;
  color: #cacaca;
}

h1,
.h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2,
.h2 {
  color: #124d5e;
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 75rem;
}

h3,
.h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5,
.h5,
.single .article-content blockquote p,
.widget ul li {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6,
.h6,
.rp4wp-related-posts h3 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print,
screen and (min-width: 40em) {
  h1,
  .h1 {
    font-size: 3rem;
  }
  h2,
  .h2 {
    font-size: 2rem;
  }
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
  }
  h5,
  .h5,
  .single .article-content blockquote p,
  .widget ul li {
    font-size: 1.25rem;
  }
  h6,
  .h6,
  .rp4wp-related-posts h3 {
    font-size: 1rem;
  }
}

@media print,
screen and (min-width: 64em) {
  h1,
  .h1 {
    font-size: 3rem;
  }
  h2,
  .h2 {
    font-size: 2rem;
  }
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.5625rem;
  }
  h5,
  .h5,
  .single .article-content blockquote p,
  .widget ul li {
    font-size: 1.25rem;
  }
  h6,
  .h6,
  .rp4wp-related-posts h3 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #15596d;
  text-decoration: underline;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #124d5e;
  text-decoration: none;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr,
abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p+.stat {
  margin-top: -1rem;
}

ul.no-bullet,
ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print,
screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print,
screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}

input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem;
}

[type='checkbox']+label,
[type='radio']+label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

[type='checkbox']+label[for],
[type='radio']+label[for] {
  cursor: pointer;
}

label>[type='checkbox'],
label>[type='radio'] {
  margin-right: 0.5rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}

label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}

.input-group> :first-child {
  border-radius: 0 0 0 0;
}

.input-group> :last-child>* {
  border-radius: 0 0 0 0;
}

.input-group-label,
.input-group-field,
.input-group-button,
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37;
}

.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #15596d;
  color: #fefefe;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover,
.button:focus {
  background-color: #124c5d;
  color: #fefefe;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #15596d;
  color: #fefefe;
}

.button.primary:hover,
.button.primary:focus {
  background-color: #114757;
  color: #fefefe;
}

.button.secondary {
  background-color: #f47521;
  color: #0a0a0a;
}

.button.secondary:hover,
.button.secondary:focus {
  background-color: #d35a0a;
  color: #0a0a0a;
}

.button.success {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button.success:hover,
.button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}

.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.warning:hover,
.button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button.alert {
  background-color: #cc4b37;
  color: #fefefe;
}

.button.alert:hover,
.button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe;
}

.button.disabled,
.button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled,
.button.disabled:hover,
.button.disabled:focus,
.button[disabled],
.button[disabled]:hover,
.button[disabled]:focus {
  background-color: #15596d;
  color: #fefefe;
}

.button.disabled.primary,
.button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary,
.button.disabled.primary:hover,
.button.disabled.primary:focus,
.button[disabled].primary,
.button[disabled].primary:hover,
.button[disabled].primary:focus {
  background-color: #15596d;
  color: #fefefe;
}

.button.disabled.secondary,
.button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary,
.button.disabled.secondary:hover,
.button.disabled.secondary:focus,
.button[disabled].secondary,
.button[disabled].secondary:hover,
.button[disabled].secondary:focus {
  background-color: #f47521;
  color: #0a0a0a;
}

.button.disabled.success,
.button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success,
.button.disabled.success:hover,
.button.disabled.success:focus,
.button[disabled].success,
.button[disabled].success:hover,
.button[disabled].success:focus {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button.disabled.warning,
.button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning,
.button.disabled.warning:hover,
.button.disabled.warning:focus,
.button[disabled].warning,
.button[disabled].warning:hover,
.button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.disabled.alert,
.button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert,
.button.disabled.alert:hover,
.button.disabled.alert:focus,
.button[disabled].alert,
.button[disabled].alert:hover,
.button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe;
}

.button.hollow {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.hollow,
.button.hollow:hover,
.button.hollow:focus {
  background-color: transparent;
}

.button.hollow.disabled,
.button.hollow.disabled:hover,
.button.hollow.disabled:focus,
.button.hollow[disabled],
.button.hollow[disabled]:hover,
.button.hollow[disabled]:focus {
  background-color: transparent;
}

.button.hollow:hover,
.button.hollow:focus {
  border-color: #0b2d37;
  color: #0b2d37;
}

.button.hollow:hover.disabled,
.button.hollow:hover[disabled],
.button.hollow:focus.disabled,
.button.hollow:focus[disabled] {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.hollow.primary {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.hollow.primary:hover,
.button.hollow.primary:focus {
  border-color: #0b2d37;
  color: #0b2d37;
}

.button.hollow.primary:hover.disabled,
.button.hollow.primary:hover[disabled],
.button.hollow.primary:focus.disabled,
.button.hollow.primary:focus[disabled] {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.hollow.secondary {
  border: 1px solid #f47521;
  color: #f47521;
}

.button.hollow.secondary:hover,
.button.hollow.secondary:focus {
  border-color: #843807;
  color: #843807;
}

.button.hollow.secondary:hover.disabled,
.button.hollow.secondary:hover[disabled],
.button.hollow.secondary:focus.disabled,
.button.hollow.secondary:focus[disabled] {
  border: 1px solid #f47521;
  color: #f47521;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover,
.button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.success:hover.disabled,
.button.hollow.success:hover[disabled],
.button.hollow.success:focus.disabled,
.button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover,
.button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled,
.button.hollow.warning:hover[disabled],
.button.hollow.warning:focus.disabled,
.button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.hollow.alert:hover,
.button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.hollow.alert:hover.disabled,
.button.hollow.alert:hover[disabled],
.button.hollow.alert:focus.disabled,
.button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.clear,
.button.clear:hover,
.button.clear:focus {
  background-color: transparent;
}

.button.clear.disabled,
.button.clear.disabled:hover,
.button.clear.disabled:focus,
.button.clear[disabled],
.button.clear[disabled]:hover,
.button.clear[disabled]:focus {
  background-color: transparent;
}

.button.clear:hover,
.button.clear:focus {
  border-color: #0b2d37;
  color: #0b2d37;
}

.button.clear:hover.disabled,
.button.clear:hover[disabled],
.button.clear:focus.disabled,
.button.clear:focus[disabled] {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.clear,
.button.clear.disabled,
.button.clear[disabled],
.button.clear:hover,
.button.clear:hover.disabled,
.button.clear:hover[disabled],
.button.clear:focus,
.button.clear:focus.disabled,
.button.clear:focus[disabled] {
  border-color: transparent;
}

.button.clear.primary {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.clear.primary:hover,
.button.clear.primary:focus {
  border-color: #0b2d37;
  color: #0b2d37;
}

.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled] {
  border: 1px solid #15596d;
  color: #15596d;
}

.button.clear.primary,
.button.clear.primary.disabled,
.button.clear.primary[disabled],
.button.clear.primary:hover,
.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.button.clear.primary:focus,
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled] {
  border-color: transparent;
}

.button.clear.secondary {
  border: 1px solid #f47521;
  color: #f47521;
}

.button.clear.secondary:hover,
.button.clear.secondary:focus {
  border-color: #843807;
  color: #843807;
}

.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled] {
  border: 1px solid #f47521;
  color: #f47521;
}

.button.clear.secondary,
.button.clear.secondary.disabled,
.button.clear.secondary[disabled],
.button.clear.secondary:hover,
.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.button.clear.secondary:focus,
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled] {
  border-color: transparent;
}

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success:hover,
.button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success,
.button.clear.success.disabled,
.button.clear.success[disabled],
.button.clear.success:hover,
.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.button.clear.success:focus,
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled] {
  border-color: transparent;
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover,
.button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning,
.button.clear.warning.disabled,
.button.clear.warning[disabled],
.button.clear.warning:hover,
.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.button.clear.warning:focus,
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled] {
  border-color: transparent;
}

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert:hover,
.button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert,
.button.clear.alert.disabled,
.button.clear.alert[disabled],
.button.clear.alert:hover,
.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.button.clear.alert:focus,
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled] {
  border-color: transparent;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #15596d;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #15596d;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #f47521;
}

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover,
a.button:focus {
  text-decoration: none;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child> :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child> :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #15596d;
}

:last-child:not(.is-active)>.accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

.accordion-title:hover,
.accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active>.accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}

:last-child>.accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.accordion-menu li {
  width: 100%;
}

.accordion-menu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem;
}

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a {
  position: relative;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #15596d transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.accordion-menu.align-left .is-accordion-submenu-parent>a::after {
  left: auto;
  right: 1rem;
}

.accordion-menu.align-right .is-accordion-submenu-parent>a::after {
  right: auto;
  left: 1rem;
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true']>a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle>a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #15596d transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #15596d;
  color: #fefefe;
}

.badge.primary {
  background: #15596d;
  color: #fefefe;
}

.badge.secondary {
  background: #f47521;
  color: #0a0a0a;
}

.badge.success {
  background: #3adb76;
  color: #0a0a0a;
}

.badge.warning {
  background: #ffae00;
  color: #0a0a0a;
}

.badge.alert {
  background: #cc4b37;
  color: #fefefe;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}

.breadcrumbs::before,
.breadcrumbs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.breadcrumbs::after {
  clear: both;
}

.breadcrumbs li {
  float: left;
  font-size: 0.6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}

.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}

.breadcrumbs a {
  color: #15596d;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.button-group::before,
.button-group::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.button-group::after {
  clear: both;
}

.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}

.button-group .button:last-child {
  margin-right: 0;
}

.button-group.tiny .button {
  font-size: 0.6rem;
}

.button-group.small .button {
  font-size: 0.75rem;
}

.button-group.large .button {
  font-size: 1.25rem;
}

.button-group.expanded .button {
  flex: 1 1 0px;
}

.button-group.primary .button {
  background-color: #15596d;
  color: #fefefe;
}

.button-group.primary .button:hover,
.button-group.primary .button:focus {
  background-color: #114757;
  color: #fefefe;
}

.button-group.secondary .button {
  background-color: #f47521;
  color: #0a0a0a;
}

.button-group.secondary .button:hover,
.button-group.secondary .button:focus {
  background-color: #d35a0a;
  color: #0a0a0a;
}

.button-group.success .button {
  background-color: #3adb76;
  color: #0a0a0a;
}

.button-group.success .button:hover,
.button-group.success .button:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}

.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button-group.warning .button:hover,
.button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}

.button-group.alert .button {
  background-color: #cc4b37;
  color: #fefefe;
}

.button-group.alert .button:hover,
.button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fefefe;
}

.button-group.stacked,
.button-group.stacked-for-small,
.button-group.stacked-for-medium {
  flex-wrap: wrap;
}

.button-group.stacked .button,
.button-group.stacked-for-small .button,
.button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}

.button-group.stacked .button:last-child,
.button-group.stacked-for-small .button:last-child,
.button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}

@media print,
screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media print,
screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a;
}

.callout> :first-child {
  margin-top: 0;
}

.callout> :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #cfedf6;
  color: #0a0a0a;
}

.callout.secondary {
  background-color: #fdeade;
  color: #0a0a0a;
}

.callout.success {
  background-color: #e1faea;
  color: #0a0a0a;
}

.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}

.callout.alert {
  background-color: #f7e4e1;
  color: #0a0a0a;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
}

.card> :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
}

.card-divider> :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}

.card-section> :last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover,
.close-button:focus {
  color: #0a0a0a;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button,
.close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

[data-whatinput='mouse'] .menu li {
  outline: 0;
}

.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu input {
  display: inline-block;
}

.menu,
.menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}

.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}

.menu.expanded li {
  flex: 1 1 0px;
}

.menu.simple {
  align-items: center;
}

.menu.simple li+li {
  margin-left: 1rem;
}

.menu.simple a {
  padding: 0;
}

@media print,
screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}

@media print,
screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}

.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}

.menu.icons a {
  display: flex;
}

.menu.icon-top a,
.menu.icon-right a,
.menu.icon-bottom a,
.menu.icon-left a {
  display: flex;
}

.menu.icon-left li a {
  flex-flow: row nowrap;
}

.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}

.menu.icon-right li a {
  flex-flow: row nowrap;
}

.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}

.menu.icon-top li a {
  flex-flow: column nowrap;
}

.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-bottom li a {
  flex-flow: column nowrap;
}

.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu .is-active>a {
  background: #15596d;
  color: #fefefe;
}

.menu .active>a {
  background: #15596d;
  color: #fefefe;
}

.menu.align-left {
  justify-content: flex-start;
}

.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}

.menu.align-right li .submenu li {
  justify-content: flex-start;
}

.menu.align-right.vertical li {
  display: block;
  text-align: right;
}

.menu.align-right.vertical li .submenu li {
  text-align: right;
}

.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}

.menu.align-center li {
  display: flex;
  justify-content: center;
}

.menu.align-center li .submenu li {
  justify-content: flex-start;
}

.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered>.menu {
  justify-content: center;
}

.menu-centered>.menu li {
  display: flex;
  justify-content: center;
}

.menu-centered>.menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: '';
}

.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}

.is-drilldown li {
  display: block;
}

.is-drilldown.animate-height {
  transition: height 0.5s;
}

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe;
}

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear;
}

.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}

.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}

.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem;
}

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}

.drilldown .is-drilldown-submenu-parent>a {
  position: relative;
}

.drilldown .is-drilldown-submenu-parent>a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #15596d;
}

.drilldown.align-left .is-drilldown-submenu-parent>a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #15596d;
}

.drilldown.align-right .is-drilldown-submenu-parent>a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #15596d transparent transparent;
}

.drilldown .js-drilldown-back>a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #15596d transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem;
}

.dropdown-pane.is-opening {
  display: block;
}

.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu>li.opens-left>.is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #15596d transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}

.dropdown.menu a {
  padding: 0.7rem 1rem;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.dropdown.menu .is-active>a {
  background: transparent;
  color: #15596d;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}

.dropdown.menu.vertical>li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical>li.opens-left>.is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}

.dropdown.menu.vertical>li.opens-right>.is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical>li>a::after {
  right: 14px;
}

.dropdown.menu.vertical>li.opens-left>a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #15596d transparent transparent;
}

.dropdown.menu.vertical>li.opens-right>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #15596d;
}

@media print,
screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal>li.opens-left>.is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal>li.opens-right>.is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #15596d transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical>li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical>li.opens-left>.is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical>li>a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical>li.opens-left>a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #15596d transparent transparent;
  }
  .dropdown.menu.medium-vertical>li.opens-right>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #15596d;
  }
}

@media print,
screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal>li.opens-left>.is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #15596d transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical>li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical>li.opens-left>.is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical>li.opens-right>.is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical>li>a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical>li.opens-left>a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #15596d transparent transparent;
  }
  .dropdown.menu.large-vertical>li.opens-right>a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #15596d;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner>.is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left>.is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe;
}

.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}

.is-dropdown-submenu .is-dropdown-submenu-parent>a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left>a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #15596d transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right>a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #15596d;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}

.is-dropdown-submenu>li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #15596d;
  color: #fefefe;
}

.label.primary {
  background: #15596d;
  color: #fefefe;
}

.label.secondary {
  background: #f47521;
  color: #0a0a0a;
}

.label.success {
  background: #3adb76;
  color: #0a0a0a;
}

.label.warning {
  background: #ffae00;
  color: #0a0a0a;
}

.label.alert {
  background: #cc4b37;
  color: #fefefe;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}

.media-object img {
  max-width: none;
}

@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}

.media-object-section:first-child {
  padding-right: 1rem;
}

.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}

.media-object-section> :last-child {
  margin-bottom: 0;
}

.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}

.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}

.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}

.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas {
  outline: 0;
}

.off-canvas.is-transition-push {
  z-index: 12;
}

.off-canvas.is-closed {
  visibility: hidden;
}

.off-canvas.is-transition-overlap {
  z-index: 13;
}

.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}

.off-canvas.is-open {
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6;
}

[data-whatinput='mouse'] .off-canvas-absolute {
  outline: 0;
}

.off-canvas-absolute.is-transition-push {
  z-index: 12;
}

.off-canvas-absolute.is-closed {
  visibility: hidden;
}

.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}

.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
}

.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px);
}

.off-canvas-content .off-canvas.position-left {
  transform: translateX(-250px);
}

.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-left.has-transition-push {
  transform: translateX(250px);
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px);
}

.off-canvas-content .off-canvas.position-right {
  transform: translateX(250px);
}

.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-right.has-transition-push {
  transform: translateX(-250px);
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px);
}

.off-canvas-content .off-canvas.position-top {
  transform: translateY(-250px);
}

.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-top.has-transition-push {
  transform: translateY(250px);
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px);
}

.off-canvas-content .off-canvas.position-bottom {
  transform: translateY(250px);
}

.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-bottom.has-transition-push {
  transform: translateY(-250px);
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}

.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print,
screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-medium~.off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-medium~.off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-medium~.off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-medium~.off-canvas-content {
    margin-bottom: 250px;
  }
}

@media print,
screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-large~.off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-large~.off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-large~.off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-large~.off-canvas-content {
    margin-bottom: 250px;
  }
}

@media print,
screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left,
  .off-canvas.in-canvas-for-medium.position-right,
  .off-canvas.in-canvas-for-medium.position-top,
  .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}

@media print,
screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left,
  .off-canvas.in-canvas-for-large.position-right,
  .off-canvas.in-canvas-for-large.position-top,
  .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe;
}

.orbit-previous,
.orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe;
}

[data-whatinput='mouse'] .orbit-previous,
[data-whatinput='mouse'] .orbit-next {
  outline: 0;
}

.orbit-previous:hover,
.orbit-next:hover,
.orbit-previous:active,
.orbit-next:active,
.orbit-previous:focus,
.orbit-next:focus {
  background-color: rgba(10, 10, 10, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}

.orbit-bullets button:hover {
  background-color: #8a8a8a;
}

.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}

.pagination::before,
.pagination::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.pagination::after {
  clear: both;
}

.pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
  font-size: 0.875rem;
  display: none;
}

.pagination li:last-child,
.pagination li:first-child {
  display: inline-block;
}

@media print,
screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}

.pagination a,
.pagination button {
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #0a0a0a;
}

.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}

.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #15596d;
  color: #fefefe;
  cursor: default;
}

.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #cacaca;
  cursor: not-allowed;
}

.pagination .disabled:hover {
  background: transparent;
}

.pagination .ellipsis::after {
  padding: 0.1875rem 0.625rem;
  content: '\2026';
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab';
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb';
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}

.progress.primary .progress-meter {
  background-color: #15596d;
}

.progress.secondary .progress-meter {
  background-color: #f47521;
}

.progress.success .progress-meter {
  background-color: #3adb76;
}

.progress.warning .progress-meter {
  background-color: #ffae00;
}

.progress.alert .progress-meter {
  background-color: #cc4b37;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #15596d;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}

.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #15596d;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}

[data-whatinput='mouse'] .slider-handle {
  outline: 0;
}

.slider-handle:hover {
  background-color: #124c5d;
}

.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}

.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}

.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}

.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

html.is-reveal-open {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

html.is-reveal-open body {
  overflow: hidden;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print,
screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column,
.reveal .columns {
  min-width: 0;
}

.reveal> :last-child {
  margin-bottom: 0;
}

@media print,
screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 75rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print,
screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
}

@media print,
screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
}

@media print,
screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}

input+.switch-paddle {
  margin: 0;
}

.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fefefe;
  transition: all 0.25s ease-out;
  content: '';
}

input:checked~.switch-paddle {
  background: #15596d;
}

input:checked~.switch-paddle::after {
  left: 2.25rem;
}

[data-whatinput='mouse'] input:focus~.switch-paddle {
  outline: 0;
}

.switch-active,
.switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}

input:checked+label>.switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}

input:checked+label>.switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}

.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.625rem;
}

.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked~.switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}

.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.75rem;
}

.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked~.switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}

.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem;
}

.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked~.switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}

thead,
tbody,
tfoot {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
}

thead {
  background: #f8f8f8;
  color: #0a0a0a;
}

tfoot {
  background: #f1f1f1;
  color: #0a0a0a;
}

thead tr,
tfoot tr {
  background: transparent;
}

thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f1f1f1;
}

table.unstriped tbody {
  background-color: #fefefe;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fefefe;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}

table.hover tfoot tr:hover {
  background-color: #ececec;
}

table.hover tbody tr:hover {
  background-color: #f9f9f9;
}

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec;
}

.table-scroll {
  overflow-x: auto;
}

.table-scroll table {
  width: auto;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}

a.thumbnail:hover,
a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(21, 89, 109, 0.5);
}

a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe;
}

.tooltip::before {
  position: absolute;
}

.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a;
  bottom: 100%;
}

.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto;
}

.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a;
  left: 100%;
}

.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  right: 100%;
  left: auto;
}

.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}

.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}

.tooltip.align-left::before {
  left: 10%;
  right: auto;
}

.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}

.top-bar,
.top-bar ul {
  background-color: #e6e6e6;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print,
screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em),
screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print,
screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em),
screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print,
screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em),
screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active,
.show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-left.vertical.menu>li>a {
  justify-content: flex-start;
}

.align-right.vertical.menu>li>a {
  justify-content: flex-end;
}

.align-center.vertical.menu>li>a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print,
screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print,
screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print,
screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print,
screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.site {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 100;
}

.meta-label {
  font-family: "proxima-nova";
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
}

header.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 1rem 0rem 1rem 1rem;
}

@media print,
screen and (min-width: 64em) {
  header.header-main {
    background-color: transparent;
    padding: 3rem 0;
  }
}

header.header-main .branding h1.site-title {
  margin: 0;
  font-size: 1rem;
  line-height: 0;
}

header.header-main .branding h1.site-title a {
  width: 150px;
}

@media print,
screen and (min-width: 64em) {
  header.header-main .branding h1.site-title a {
    width: 200px;
  }
}

header.header-main .branding a {
  display: block;
}

body {
  background-color: transparent;
  border-top: 15px solid #15596d;
  border-image: linear-gradient(to right,#15596d,#f47521) 1 0 0 0;
}

.bg {
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.menu-item-container {
  background: #15596d;
}

.menu-item-container:hover .menu-item, .menu-item-container:active .menu-item, .menu-item-container:focus .menu-item {
  background: #15596d;
  color: white;
}

.menu-item-children {
  background: #15596d;
  flex-direction: column;
  padding: 0 0 0.5rem 0.5rem;
  width: 100%;
}

.menu-item-children-promos {
  display: none;
}

.menu-item-child {
  background: #15596d;
  color: white;
  display: block;
  flex-grow: 1;
  font-size: 19px;
  padding: 0.25rem 0.5rem !important;
}

@media print, screen and (min-width: 64em) {
  .menu-item-child {
    text-align: center;
  }

  a.menu-item-child:hover, a.menu-item-child:active, a.menu-item-child:focus {
    background-color: #f47521;
  }
}

a.menu-item-child, a.menu-item-child:link, a.menu-item-child:visited, a.menu-item-child:active, a.menu-item-child:hover {
  color: white;
}

@media print, screen and (min-width: 64em) {
  .menu-item-children {
    align-items: flex-end;
    background-color: #15596d;
    display: none;
    filter: drop-shadow(black 0 1rem 10px);
    justify-content: flex-end;
    margin-top: 2px;
    min-width: 450px;
    padding: .9rem 0;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 1000;
  }

  .menu-item-children-items {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
  }

  .menu-item-children-promos {
    display: none;
    flex-direction: column;
    margin: 0 5px;
  }

  .menu-item-children-promos-items {
    display: flex;
    flex-wrap: wrap;
  }

  .menu-item-children-promos-title {
    border-top: 1px solid black;
    color: white;
    font-size: 16px;
    margin: 5px 10px;
    padding: 10px;
    text-align: center;
  }

  .menu-item-container {
    background: transparent;
  }

  .menu-item-promo {
    display: flex;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 !important;
    width: 200px;
  }

  .menu-item-promo, a.menu-item-promo, a.menu-item-promo:link, a.menu-item-promo:visited, a.menu-item-promo:hover, a.menu-item-promo:active, a.menu-item-promo:focus {
    color: white;
  }

  .menu-item-promo-image {
    background-position: center;
    background-size: cover;
    display: inline-block;
    height: 120px;
    width: 200px;
  }

  .menu-item-promo, .menu-item-promo a, .menu-item-promo a:link, .menu-item-promo a:visited, .menu-item-promo a:hover, .menu-item-promo a:active, .menu-item-promo a:focus  {
    color: white;
  }

  .menu-item-promo-text {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.7777485994397759) 46%, rgba(0,0,0,0) 100%);
    display: inline-block;
    height: 100%;
    line-height: 1.1;
    padding: 0.9rem 0.9rem 0.2rem;
    width: 100%;
  }
}

.bg--dark a.meta-label {
  color: #fff;
  color: #fefefe;
}

.icon {
  display: flex;
  padding: 0;
  margin: 0;
  width: 50px;
}

.icon svg {
  margin: 0;
  padding: 0;
  align-items: center;
  flex: 0 0 50px;
  height: 50px;
}

.single article {
  padding-top: 2rem;
}

.single article.next-post h3 {
  font-weight: 700;
}

.single article.next-post h3 a {
  color: #000;
}

.single .article-content {
  position: relative;
  background-color: #fff;
  padding: 0 2rem 0.5rem;
  font-size: 112.5%;
  flex-wrap: nowrap !important;
}

.single .article-content .cats {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.single .article-content .cats > * {
  margin-right: 0.5rem;
}

.single .article-content .posted__in {
  background-color: #124d5e;
  border-radius: 5px;
  color: white;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
}

a.posted__in,
a.posted__in:link,
a.posted__in:visited,
a.posted__in:hover,
a.posted__in:active,
a.posted__in:focus {
  text-decoration: none;
}

.single .article-content blockquote {
  border-color: #f47521;
  border-width: 3px;
  margin: 0;
  padding: 0;
}

.single .article-content blockquote p {
  font-weight: 200;
  color: #000;
  margin: 2rem 0;
  padding-left: 1rem;
}

@media print,
screen and (min-width: 64em) {
  .single .article-content {
    flex: 0 1 75%;
    max-width: calc(75% - 25px);
  }
  .page-template-page-no-image .single .article-content {
    margin-top: 0;
  }
}

.single .sidebar {
  flex: 1 1 100%;
}

@media print,
screen and (min-width: 64em) {
  .single .sidebar {
    flex: 1 1 25%;
    margin-left: 25px;
  }
}

.single .sidebar a {
  text-decoration: none;
}

.p1 {
  padding: 1rem;
}

.article-h1,
.featured-h1,
.post-h1 {
  font-weight: 700;
  width: 100%;
}

@media print,
screen and (min-width: 40em) {
  .article-h1,
  .featured-h1,
  .post-h1 {
    font-weight: 700;
  }
}

.blog-author {
  font-family: "proxima-nova-condensed";
  font-size: 14px;
}

.blog-author * {
  color: #f47521 !important;
}

.blog-author a {
  border-bottom: 1px solid #000;
}

.owl-dots {
  margin: -1.0rem auto 0 auto;
  background-color: #fff;
  border-radius: 60px;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  display: table;
}

@media print,
screen and (min-width: 64em) {
  .owl-dots {
    margin: -1.25rem auto 0 auto;
    padding: 0.5rem 1rem;
  }
  .owl-dots button.owl-dot {
    margin: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.owl-dots button.owl-dot {
  margin: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0 0 0px 2px #15596d;
  transition: all 0.5s ease-in-out;
}

@media print,
screen and (min-width: 64em) {
  .owl-dots button.owl-dot {
    margin: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}

.owl-dots button.owl-dot.active {
  background-color: #fff;
  box-shadow: inset 0 0 0px 10px #15596d;
}

.owl-dots button.owl-dot span {
  width: 100%;
  height: 100%;
  display: block;
  background-color: 777;
}

.featured-content {
  margin-bottom: 2rem;
}

@media print,
screen and (min-width: 40em) {
  .featured-content {
    margin-top: 2rem;
  }
}

.topic-browser {
  width: 100%;
}

.article-list {
  display: flex;
  flex-wrap: wrap;
}

.article-list p.excerpt {
  margin-bottom: 0;
}

.article-list .h3 {
  padding: 2rem 0;
}

.article-list article {
  flex: 1 1 100%;
  flex-wrap: wrap;
  background-color: #fff;
}

.article-list.resources article {
  background-color: inherit;
}

.article-list article h5 {
  font-weight: 700;
}

.article-list article>header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 1rem;
}

@media print,
screen and (min-width: 40em) {
  .article-list article {
    flex: 1 1 25%;
    padding: 1rem 1rem 0 1rem;
  }
  .article-list.resources article {
    padding: 1rem;
  }
  .article-list article p {
    padding-right: 1rem;
  }
}

.article-list h1 {
  padding-top: 1rem;
  font-weight: 700;
}

.article-list-numbered {
  padding: 1rem;
  flex-wrap: nowrap !important;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.article-list-numbered-subtitle {
  padding-top: 0.3rem;
  margin-bottom: 0;
}

.article-list-numbered-title {
  font-weight: 700;
}

.article-list-numbered span.bordered {
  padding: 0.5rem;
  font-weight: 700;
  padding: 0 0.50rem;
  border: 3px solid #f47521;
  margin-right: 0.5rem;
}

.article-list-numbered>* {
  flex: 0 1 auto;
}

.article-list-numbered a {
  color: #000;
}

@media print,
screen and (min-width: 64em) {
  .article-list-numbered {
    padding: 0;
  }
  .article-list-numbered-title {
    padding-right: 1rem;
  }
}

.tabs-panel {
  display: none;
}

.tabs-panel.is-active {
  display: block;
}

.tabs {
  margin: 0;
  list-style: none;
  display: flex;
}

.tabs .tabs-title {
  margin-bottom: 0;
  padding: 5px;
}

@media print,
screen and (min-width: 40em) {
  .tabs .tabs-title {
    margin-right: 0.5rem;
    padding: 1rem;
  }
}

.tabs .tabs-title.is-active {
  font-weight: 700;
  background-color: #fff;
}

.content-wrapper {
  padding: 1rem;
}

.tabs-content {
  background-color: #fff;
  padding: 1rem;
  transition: all 0.5s ease-in-out;
}

.featured-slide {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.featured-slide figure {
  margin: 0;
}

.featured-slide img {
  display: block;
}

.featured-slide>* {
  flex: 1 1 100%;
}

.featured-slide-content {
  margin-left: -80%;
  padding: 4rem 2rem;
  margin-right: 4rem;
}

.featured-slide-content p {
  font-family: 'proxima-nova-condensed';
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.6;
  vertical-align: middle;
  text-align: center;
}

.featured-slide-content p i {
  font-weight: 700;
  border-bottom: 1px solid #edf5f4;
  border-top: 1px solid #edf5f4;
}

.text-slide {
  position: relative;
  max-height: 550px;
}

.text-slide .tinted-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-slide .tinted-overlay h4,
.text-slide .tinted-overlay b,
.text-slide .tinted-overlay p {
  color: #fff;
}

.text-slide .tinted-overlay h4.h2 {
  font-weight: 700;
}

.text-slide .tinted-overlay b {
  font-weight: 700;
}

@media print,
screen and (min-width: 64em) {
  .text-slide .tinted-overlay {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.flex-column {
  flex-direction: column;
}

.cta-parent .cta {
  font-weight: 700;
  display: inline-block;
  padding: 0.5rem;
  background-color: #15596d;
  color: #fff !important;
}

.cta-parent .cta-secondary {
  background-color: #f47521;
}

@media print,
screen and (min-width: 64em) {
  .cta-parent .cta {
    padding: 1rem;
  }
}

.tiles {
  display: flex;
  flex-wrap: wrap;
}

.tiles>* {
  flex: 1 1 100%;
}

@media print,
screen and (min-width: 64em) {
  .tiles>* {
    flex: 0 1 25%;
  }
}

.tile {
  background-color: #fff;
  padding: 1rem;
}

.tile-p p {
  margin: 0;
  padding: 0;
}

.tile-primary {
  background-color: #f47521;
}

.tile-primary p,
.tile-primary h5 {
  color: #fff !important;
  font-weight: 700;
}

.tile-primary p {
  font-weight: 400;
}

@media print,
screen and (min-width: 40em) {
  .tile-person {
    flex: 1 1 50%;
  }
}

@media print,
screen and (min-width: 64em) {
  .tile-person {
    flex: 0 1 25%;
  }
}

.tile-person figure {
  display: block;
  margin-bottom: 1rem;
}

.tile-person figure.rigths {
  object-position: right center;
}

.tile-person h5 {
  font-weight: 700;
  margin-bottom: 0;
}

.centered-p {
  width: 100%;
  display: block;
  text-align: center;
  font-weight: 700;
}

.tabs-content {
  flex: 1 0 100% !important;
}

.content-wrapper {
  -webkit-animation: fadein 0.5s ease-in;
  /* Safari, Chrome and Opera > 10.5. ease-in1 */
  -moz-animation: fadein 0.5s ease-in;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s ease-in;
  /* Internet Explorer */
  -o-animation: fadein 0.5s ease-in;
  /* Opera < 10.5. ease-in1 */
  animation: fadein 0.5s ease-in;
}

.twitter-icon {
  display: block;
}

.twitter-icon svg {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
}

.widget {
  margin-bottom: 2rem;
}

.widget h3,
.widget h3.h3 {
  letter-spacing: 1px;
  font-family: "proxima-nova-condensed";
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.widget .widget-text-only {
  padding-left: 1.6rem;
}

.widget figure {
  margin-bottom: 0.5rem;
}

.nav-main .menu {
  align-items: center;
}

.pullup10 {
  position: relative;
  border-top: 5px solid;
  background-color: #fff;
}

@media print,
screen and (min-width: 64em) {
  .pullup10 {
    margin: -15rem auto 0 auto;
    max-width: 80%;
  }
}

.padded-wrapper {
  padding: 1rem 1rem 0 1rem;
  background-color: #fff;
  position: relative;
  z-index: 100;
}

.widget {
  margin: 10px 0 20px;
}

.widget:not(:last-child) {
  border-bottom: 2px solid #124d5e;
  padding-bottom: 10px;
}

.widget span {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}

.message {
  padding-left: 0px;
}

@media print,
screen and (min-width: 64em) {
  .message {
    padding-left: 75px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0, -30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

body.page-template-page-contribution {
  background-color: #fff;
}

.contrib-collection {
  width: 100%;
}

.contrib-collection .contrib-title {
  background-color: #f2f2f2;
  margin-bottom: 0;
}

.contrib-collection .contrib-title a {
  display: block;
  padding: 0.5rem 1.0rem;
}

.contrib-collection .logo {
  padding: 0.5rem;
  display: table;
  margin-left: auto;
  margin-right: 10px;
  margin-top: -1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.contrib-collection .logo img {
  max-height: 60px;
  max-width: 200px;
}

.contrib-collection a {
  color: #000;
  font-weight: 700;
}

@media print,
screen and (min-width: 64em) {
  .contrib-collection {
    padding: 1rem;
  }
}

.contrib {
  margin-bottom: 1rem;
}

.contrib.featured {
  position: relative;
}

.contrib.featured .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 20%;
  background-color: #f47521;
}

.contrib.featured>a {
  color: #fff !important;
}

.contrib.featured .contrib-title {
  font-weight: 700;
  padding: 1rem;
}

.contrib.featured .img {
  position: relative;
  width: 100%;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-half {
  flex-wrap: wrap;
}

.flex-half>* {
  flex: 1 1 100%;
}

@media print,
screen and (min-width: 40em) {
  .flex-half>* {
    flex: 1 1 50%;
  }
}

.flex-full {
  flex: 1 1 100%;
}

@media print, screen and (min-width: 64em) {
  .large-0-5 {
    flex: 0 0 4.16666%;
    max-width: 4.16666%;
  }

  .large-3-5 {
    flex: 0 0 29.16666%;
    max-width: 29.16666%;
  }
}

.single .article-body {
  flex: 1 1 auto;
  background-color: #fff;
}

.single .article-body li {
  padding: 5px 0 5px 10px;
}

@media print,
screen and (min-width: 40em) {
  .single .article-body {
    flex: 0 1 70%;
  }
}

.article-sidebar {
  flex: 1 1 auto;
  background-color: #fff;
}

@media print,
screen and (min-width: 40em) {
  .article-sidebar {
    flex: 0 1 30%;
  }
}

a.button {
  color: #fff !important;
  font-weight: 700;
}

a.button.moss {
  background-color: #7f9b7f !important;
}

a.button.stroke {
  border: 3px solid #15596d;
  color: #000 !important;
  background-color: #fff;
}

a.button.stroke:hover {
  border-color: #f47521;
}

h3.sub-title {
  font-family: 'proxima-nova-condensed';
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.6;
  vertical-align: middle;
}

h3.sub-title i {
  font-weight: 700;
  border-bottom: 1px solid #edf5f4;
  border-top: 1px solid #edf5f4;
}

footer.main {
  background: linear-gradient(90deg, rgb(21, 89, 109) 25%, rgb(244, 117, 33) 100%);
  padding: 2rem 1rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer>* {
  flex: 0 1 auto;
}

.footer ul {
  list-style: none;
}

.footer .logo {
  width: 150px;
}

@media print,
screen and (min-width: 40em) {
  .footer .logo {
    width: 200px;
  }
}

.footer .contact a {
  color: #fff;
}

a.icon-button {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  fill: #15596d;
  margin-right: 5px;
}

a.icon-button svg {
  width: 25px;
  height: 25px;
}

a.icon-button:hover svg {
  fill: #f47521;
}

.temp-nav {
  align-items: center;
  display: flex;
  position: relative;
}

.temp-nav a {
  padding: 0.4rem 0.9rem 0.2rem;
  text-decoration: none;
}

.temp-nav svg {
  fill: #15596d;
}

.never-current {
  border: 0 !important;
}

.widget-title,
.rp4wp-related-posts h3,
.section-divider {
  font-family: "proxima-nova-condensed";
  text-transform: uppercase;
  font-weight: 700;
}

.widget-title-inverted,
.section-divider-inverted {
  background-color: #f47521;
  margin: 2rem 0;
  padding: 0.25rem 0;
}

.widget-title-inverted span,
.section-divider-inverted span {
  display: table;
  margin: 0.25rem 0.5rem;
  padding: 0.2rem 0.5rem 0;
  background-color: #fff;
}

.widget-title-inverted:before,
.section-divider-inverted:before {
  display: none;
}

.single-people .button {
  border: 3px solid #f47521;
  background-color: #fff;
  color: #15596d !important;
}

@media print, screen and (max-width: 63.99em) {
  .header-top-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.page .full-size-header {
  margin-top: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;
  position: relative;
  border-bottom: 6px solid #a49667;
}

@media print,
screen and (min-width: 64em) {
  .page .full-size-header {
    padding: 7rem 0;
  }
}

.page .full-size-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.page .full-size-header h1 {
  color: #fff;
  background-color: #f47521;
  padding: 0 1rem;
  position: relative;
  z-index: 300;
  margin: 0;
}

.page .full-size-header h3 {
  margin: 0;
  background-color: #fff;
  position: relative;
  padding: 1rem;
}

.page .full-size-header h1 {
  max-width: 75%;
}

.row.full-width {
  max-width: none;
}

.heading-row .intro ul.sub-menu {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.heading-row .intro ul.sub-menu li {
  margin-right: 1rem;
}

.heading-row .intro ul.sub-menu li:last-child {
  margin-right: 0;
}

.widget .widget-title,
.widget .rp4wp-related-posts h3,
.rp4wp-related-posts .widget h3 {
  color: #f47521;
}

.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget ul li {
  font-weight: 700;
}

.wp-caption-text {
  margin-bottom: 1rem;
  padding-top: 1rem;
  font-family: "proxima-nova-condensed";
  color: #444;
  font-style: italic;
}

.tiled-gallery,
.gallery,
iframe {
  margin-bottom: 1.5rem !important;
}

.article-body figure,
.article-body h1,
.article-body h2,
.article-body h3,
.article-body h4,
.article-body h5,
.article-body h6,
.article-body p,
.article-body ul {
  width: 100%;
}

.article-body h1,
.article-body h2,
.article-body h3,
.article-body h4,
.article-body h5,
.article-body h6 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.p1 {
  padding: 1rem;
}

.article-body, p {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.upper {
  text-transform: uppercase;
}

.secondary {
  color: #f47521;
}

.white-fill {
  background-color: #fff;
}

.y-scroll-container {
  height: 100%;
  max-height: 600px;
  overflow-y: scroll;
}

.button {
  background-color: #f47521;
  font-weight: bold;
}

.button:hover,
.button:active,
.button:focus
{
  background-color: #f47521;
}

.entry-content img,
.comment-content img,
.widget img {
  max-width: 100%;
  /* Fluid images for posts, comments, and widgets */
}

.entry-content-asset .fluid-width-video-wrapper {
  margin-bottom: 1em;
}

img[class*="align"],
img[class*="wp-image-"] {
  height: auto;
  /* Make sure images with WordPress-added height and width >attributes are scaled correctly */
  margin-bottom: 1rem;
}

figure img[class*="align"],
figure img[class*="wp-image-"] {
  margin-bottom: 0rem;
}

img.size-full {
  max-width: 100%;
  width: auto;
  /* Prevent stretching of full-size images with height and >>width attributes in IE8 */
}

.mb0 {
  margin-bottom: 0;
}

.heateorSssEmailBackground {
  background-color: #65aaa4 !important;
}

.heateorSssCopyLinkBackground {
  background-color: #809c80 !important;
}

.term-post-collection {
  position: relative;
}

@media print,
screen and (min-width: 64em) {
  .term-post-collection>.flex {
    margin-top: 2.5rem;
  }
}

.term-post-collection>header>img {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;
}

@media print,
screen and (min-width: 64em) {
  .term-post-collection>header>img {
    position: absolute;
    margin: 0;
    right: 0;
    top: 3rem;
    margin-right: 10px;
  }
}

.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  margin: 0 -2rem 25px;
  max-height: 200px;
}

@media print, screen and (min-width: 40em) {
  .hero-image {
    height: 350px;
    max-height: 350px;
  }
}

article:not(.post-type-page) {
  margin-bottom: 1rem;
}

.article-body .rp4wp-related-posts {
  display: none;
}

.article-body pre {
  border-top: 2px solid #124d5e;
  font-size: 0.9rem !important;
  font-family: "proxima-nova-condensed";
  margin-bottom: 2rem;
  padding-top: 1rem;
  font-weight: 700;
  color: #555;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}

.project {
  &__person {
    &__image {
      height: auto;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.cli-plugin-button,
.cli-plugin-button:visited,
.medium.cli-plugin-button,
.medium.cli-plugin-button:visited {
  max-width: 0 0.5rem;
}

.contact-us-message textarea {
  width: calc(50% - 8px) !important;
}

#cookie-law-info-again {
  display: none !important;
}

.featured-list {
  width: 100%;
}

.featured-list-bottom {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.featured-list-items {
  display: flex;
  flex-wrap: wrap;
}

.featured-list-item {
  margin-bottom: 25px;
  transition: all .5s;
  width: 100%;
}

.featured-list-items-rows-1 .featured-list-item {
  display: flex;
  flex-direction: column;
}

@media print, screen and (min-width: 40em) {
  .featured-list-item {
    margin-right: 25px;
  }

  .featured-list-items-rows-1 .featured-list-item {
    align-items: center;
    flex-direction: row;
    margin-right: 0;
  }

  .featured-list-items-rows-2 .featured-list-item {
    width: calc(50% - 25px);
  }

  .featured-list-items-rows-3 .featured-list-item {
    width: calc(33% - 25px);
  }
}

a.featured-list-item:active,
a.featured-list-item:hover,
a.featured-list-item:focus {
  background-color: #E6F0F2;
}

.featured-list-item h3 {
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 0 0 10px;
}

.featured-list-items-rows-1 .featured-list-item h3 {
  font-size: 2rem;
  line-height: 1.1;
}

a.featured-list-item h3,
a.featured-list-item:link h3,
a.featured-list-item:visited h3,
a.featured-list-item:active h3,
a.featured-list-item:hover h3,
a.featured-list-item:focus h3 {
  color: #124d5e;
}

a.featured-list-item:active h3,
a.featured-list-item:hover h3,
a.featured-list-item:focus h3 {
  text-decoration: underline;
}

.featured-list-item-button {
  background-color: #f47521;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px 0;
  padding: 20px 30px;
}

.featured-list-item-button:link,
.featured-list-item-button:visited,
.featured-list-item-button:hover,
.featured-list-item-button:active,
.featured-list-item-button:focus {
  color: white;
}

.featured-list-item-button:link,
.featured-list-item-button:visited {
  text-decoration: none;
}

.featured-list-item-details {
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 10px 10px 0;
  width: calc(100% - 20px);
}

.featured-list-item-image {
  background-position: center;
  background-size: cover;
  display: inline-block;
  min-height: 200px;
  transition: all .5s;
  width: 100%;
}

a.featured-list-item:active .featured-list-item-image,
a.featured-list-item:hover .featured-list-item-image,
a.featured-list-item:focus .featured-list-item-image {
  transform: scale(1.1);
}

.featured-list-item-image-container {
  background-color: #15596d;
  border-bottom: 8px solid #15596d;
  display: inline-block;
  margin-bottom: 10px;
  max-height: 200px;
  min-height: 200px;
  overflow: clip;
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .featured-list-items-rows-1 .featured-list-item-image-container {
    margin: 30px 50px 30px 15px;
    width: 30%;
  }

  .featured-list-items-rows-1 .featured-list-item-text {
    margin: 30px 50px;
    width: 60%;
  }
}

.featured-list-item-short-description {
  color: #0a0a0a;
  display: inline-block;
  font-size: 1.2rem;
  width: 100%;
}

.featured-list-item-text {
  display: block;
  padding: 0 10px 0;
}

.featured-list-pagination {
  border-top: 1px solid #76a0ac;
  border-bottom: 1px solid #76a0ac;
  font-size: 1.25em;
  margin: 1rem 0;
  padding: 0.5em 0;
}

.form-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-filter select {
  background-color: #15596d;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: white'></polygon></svg>");
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  margin: 0;
  padding-left: 15px;
  padding-right: 2rem;
  width: 100%;
}

@media print,
screen and (min-width: 40em) {
  .form-filter select {
    min-width: 150px;
    width: calc(20% - 20px);
  }
}

.gform_wrapper ul.gform_fields li.gfield {
  padding-left: 0;
}

.heateor_sss_sharing_container {
  margin: 1rem 0;
}

.heateorSssSharingRound i {
  margin: 0 10px 10px 0;
}

.heateorSssSharingRound i.heateorSssSharing.heateorSssCopyLinkBackground {
  background-color: #ffc112 !important;
}

.heateorSssSharingRound i.heateorSssSharing.heateorSssEmailBackground {
  background-color: #649a3f !important;
}

.off-canvas.is-transition-push {
  z-index: 500 !important;
}

ol, ul, li {
  font-size: 1.2rem;
}

.page-template-page-about .article-body > .featured > .row:first-child > * {
  margin-left: 0;
  margin-right: 0;
  max-width: 800px;
  width: 100%;
}

.page-template-page-about .article-body > .featured > .row:first-child > ol,
.page-template-page-about .article-body > .featured > .row:first-child > ul
{
  margin-left: 1em;
}

.page-template-page-home .featured {
  margin-bottom: 80px;
}

.page-template-page-main-column .article-body > .featured > .row:first-child > *
{
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
}

.page-template-page-main-column .article-body > .featured > .row:first-child > ol,
.page-template-page-main-column .article-body > .featured > .row:first-child > ul
{
  margin-left: 1em;
}

.people-container {
  padding: 0;
  width: 100%;
}

.people-container .rt-container, .people-container .rt-container-fluid {
  padding: 0;
}

.people-container p {
  margin-left: 0;
}

.prev-block {
  margin: 2rem 0;
}

.p1 {
  padding: 1rem;
}

.publications-results {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.share-icon {
  height: auto;
  margin: 0 15px;
  width: 50px;
}

.share-icon-small {
  height: auto;
  margin: 0 5px;
  width: 30px;
}

.vue-map {
  height: 80vh;
  min-height: 500px;
}

.widget_nav_menu .menu {
  display: block !important;
}

.widget_nav_menu .menu a {
  padding: 0;
  margin: 0;
  margin-bottom: 0.5rem;
  padding-left: 0.2rem;
}
